
import SyllablesTestLine from './SyllablesTestLine.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '@/utils/common';
import SyllableModule from '@/store/modules/SyllableModule';
import { getModule } from 'vuex-module-decorators';

@Component({ components: { SyllablesTestLine } })
export default class SyllabesTestAnswerOption extends Vue {
  @Prop() word!: string;
  @Prop() tutorialAnswers?: number[];
  @Prop() disableButtons?: boolean;
  @Prop() isTutorial?: boolean;
  @Prop({ default: false }) noHover?: boolean;
  @Prop({ default: false }) showIcon?: boolean;

  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);

  get answers(): number[] {
    return this.tutorialAnswers ?? this.syllableModule.currentAnswers;
  }

  toggleChosen(idx: number): void {
    clickSound.play();
    if (this.answers.includes(idx)) {
      const index = this.answers.indexOf(idx);
      this.answers.splice(index, 1);
    } else {
      this.answers.push(idx);
    }
    this.answers.sort();
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isChosen(idx: number): boolean {
    return this.answers.includes(idx);
  }
}
