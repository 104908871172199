
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import { SyllablesQuestionType } from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import ArrowButton from '../../base/ArrowButton.vue';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import RetryMixin from '../RetryMixin';
import SyllablesTestAnswerOption from './SyllablesTestAnswerOption.vue';

@Component({
  components: {
    SyllablesTestAnswerOption,
    ArrowButton,
    TestEndEmoji,
    TutorialEndEmoji,
  },
})
export default class RiddleContainer extends RetryMixin {
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  started = false;
  showTutorialEnd = true;
  disableButtons = true;
  start = 0;
  stop = 0;

  get currentQuestion(): SyllablesQuestionType {
    return this.syllableModule.currentQuestion;
  }

  get questionIdx(): number {
    return this.syllableModule.result.questionsAnswered;
  }

  get isEnd(): boolean {
    return (
      this.syllableModule.overallQuestions ===
      this.syllableModule.result.questionsAnswered
    );
  }

  mounted(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 6000);
  }

  startInitial(): void {
    this.start = performance.now();
  }

  async updated(): Promise<void> {
    this.start = performance.now();

    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.syllableModule.saveStatus(
          this.userModule.currentUser._id,
        );

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.syllableModule.finishedAndSavedTest();
        }
      } catch (error: any) {
        this.syllableModule.resetDone();
        console.error(
          '[SYLLABLES] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  answerQuestion(): void {
    this.stop = performance.now();
    const time = this.stop - this.start;

    //if nothing marked, don't go further
    if (this.syllableModule.currentAnswers.length === 0) {
      return;
    }

    //CHECK IF CORRECTLY ANSWERED
    const isCorrect = this.checkAnswer();
    this.syllableModule.addAnswer({
      word: this.currentQuestion.word,
      answers: this.syllableModule.currentAnswers,
      time: Math.round(time),
      isCorrect: isCorrect,
    });
    this.syllableModule.resetAnswers();

    if (this.isEnd) {
      //play end sound
      const sound = new Audio('/audio/ausgezeichnet.mp3');
      sound.play();
    }
  }

  checkAnswer(): boolean {
    const solution = this.currentQuestion.solution;
    const answers = this.syllableModule.currentAnswers.sort((a, b) => a - b);

    return (
      solution.length === answers.length &&
      solution.every((answer, idx) => answer === answers[idx])
    );
  }
}
