import axios from 'axios';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import { getModule } from 'vuex-module-decorators';
import { KeycloakTokenDto, Role } from './api/types';
import App from './App.vue';
import { messages } from './lang/messages';
import vuetify from './plugins/vuetify';
import router from './router';
import './router/componentHooks';
import store from './store';
import UserModule from './store/modules/UserModule';
import { API_REFRESH_TOKEN } from './utils/api';
import { getSchoolPrefix } from './utils/common';
import VueMask from 'v-mask';
import { logErrors } from './utils/serverLog';

logErrors();

const userModule = getModule(UserModule, store);

//append token to requests
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && !config.url?.endsWith('token')) {
      config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

//get new token and retry request
axios.interceptors.response.use(
  (response) => response,

  async (error) => {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      router.currentRoute.name !== 'login' &&
      router.currentRoute.name !== 'school' &&
      !error.config.url.endsWith('auth/refresh')
      // &&
      // !error.config.url.includes('auth/reset-password')
    ) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        router.push({
          name: 'login',
          params: { schoolName: getSchoolPrefix() },
        });
        return Promise.reject(error);
      }
      try {
        const token: KeycloakTokenDto = await axios
          .post(
            `${API_REFRESH_TOKEN}`,
            { token: refreshToken },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .then((response) => response.data);

        localStorage.setItem('token', token.access_token!);
        localStorage.setItem('refreshToken', token.refresh_token!);
        originalRequest.headers.Authorization = `Bearer ${token.access_token}`;

        return axios(originalRequest);
      } catch (error) {
        if (userModule.currentUser.role != Role.student) {
          router.push({
            name: 'school',
            params: { schoolName: getSchoolPrefix() },
          });
        } else {
          router.push({
            name: 'login',
            params: { schoolName: getSchoolPrefix() },
          });
        }
      }
    }
    return Promise.reject(error);
  },
);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(VueMask);

const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
