
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class SyllableTestLine extends Vue {
  @Prop() isTutorial!: boolean;
  @Prop({ default: false }) show?: boolean;
  @Prop() idx?: number;
  @Prop() noHover?: boolean;

  showIcon = true;

  get cursor(): string {
    return require('@/assets/images/finger.png');
  }

  get showCursor(): boolean {
    const idxOfWrongExample = 2;
    const show = this.show! && this.idx === idxOfWrongExample;
    return show && this.showIcon;
  }

  turnIconOff(): void {
    if (this.show) {
      setTimeout(async () => {
        this.showIcon = false;
      }, 2000);
    }
  }

  updated(): void {
    this.turnIconOff();
  }
}
